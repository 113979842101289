'use client';

import React from 'react';
import Link from 'next/link';

import CategoriesIcon from '../../../public/images/tests/tests-categories.svg';
import DiseasesIcon from '../../../public/images/tests/tests-diseases.svg';
import RecommendedIcon from '../../../public/images/tests/tests-recommended.svg';
import { ageCategoryUrl, anatomicPartUrl, testsCategoryUrl } from 'src/services/route-utils';

type SectionTypes = 'categories' | 'diseases' | 'recommended';
type Section = { key: SectionTypes; name: string; description: string; icon: React.ReactNode; href: string };
type Props = {
    activeSection?: SectionTypes;
    className?: string;
    liClassName?: string;
    renderAsList?: boolean;
};

const iconClassName = 'block w-[48px] md:w-[64px]';

const sections: Section[] = [
    {
        key: 'categories',
        name: 'Toate analizele',
        description: 'Vezi toate analizele pe categorii și alege-le pe cele de care ai nevoie.',
        href: testsCategoryUrl(null),
        icon: <CategoriesIcon className={iconClassName} />,
    },
    {
        key: 'diseases',
        name: 'Afecțiuni medicale',
        description: 'Găsește analizele de care ai nevoie în funcție de afecțiunea pe care o suspectezi.',
        href: anatomicPartUrl(null),
        icon: <DiseasesIcon className={iconClassName} />,
    },
    {
        key: 'recommended',
        name: 'Analize recomandate',
        description: 'Descoperă analizele pe care ar trebui să le repeți recurent în funcție de vârstă șl sex.',
        href: ageCategoryUrl(null),
        icon: <RecommendedIcon className={iconClassName} />,
    },
];

export default function TestsNavigation({ activeSection, className, liClassName, renderAsList }: Props) {
    const renderSection = (section: Section, active: boolean, renderAsList: boolean) => {
        const blockClassName = `flex w-full items-center gap-4${
            renderAsList ? '' : ' flex-col p-2 text-center md:p-4 lg:p-6 lg:py-14 lg:flex-row lg:text-left'
        }`;
        const content = (
            <>
                {section.icon}
                <div className="flex-1">
                    <div
                        className={`font-display ${
                            renderAsList ? 'text-xl md:text-2xl' : 'text-base md:text-xl'
                        } decoration-1 underline-offset-2 group-hover:underline md:underline-offset-4${
                            active ? ' underline' : ''
                        }`}>
                        {section.name}
                    </div>
                    <div className={`mt-2${renderAsList ? ' text-sm md:text-base' : ' hidden lg:block'}`}>
                        {section.description}
                    </div>
                </div>
            </>
        );
        return active ? (
            <div className={blockClassName}>{content}</div>
        ) : (
            <Link href={section.href} className={blockClassName}>
                {content}
            </Link>
        );
    };

    return (
        <ul className={className || 'container mx-auto flex gap-2 p-4 md:gap-8'}>
            {sections.map((section) => (
                <li
                    key={section.key}
                    className={
                        liClassName ||
                        `group relative flex basis-1/3 lg:items-center ${
                            activeSection === section.key ? 'bg-primary text-white drop-shadow-lg' : 'bg-white'
                        } hover:drop-shadow-lg`
                    }>
                    {renderSection(section, activeSection === section.key, renderAsList || false)}
                </li>
            ))}
        </ul>
    );
}
